<template>
  <auth-content>
    <el-header slot="header" :title="$t('common.attractions')" :favorites="false" />

    <el-filters />

    <div v-for="(group, groupKey) in listFiltered">

      <div class="mt-3" v-if="groupKey != '_' && groupKey != 'GROUP_BOTTOM'">
        <el-card-title
          :title="$t('groups.' + groupKey)"
        />
      </div>
      <div class="mt-4" v-else-if="groupKey == 'GROUP_BOTTOM'"></div>

      <div class="row">
        <div class="col-xxl-3 col-lg-4 col-md-6 col-12 mb-3 attraction-item-wrapper" v-for="link in group" :key="link.title + link.module + link.to.name">
          <el-attraction-list-item
            v-if="link.to.name != 'attractions.index'"
            :active="link.active"
            :title="link.title"
            :description="link.description ? link.description : ''"
            :image="link.image ? link.image : null"
            :icon="link.icon"
            :to="link.to"
            :module="link.module || null"
            :permissions="link.permissions || null"
            :newlink="ifLinkNew(link)"
          />
        </div>
      </div>

    </div>


  </auth-content>
</template>


<script>

import _ from 'lodash'
import Fuse from 'fuse.js'
import Links from '../../components/common/Links'

export default {
  extends: Links,
  name: 'WorldAttractionsList',
  tag: 'world-attractions-list',
  computed: {
    listFiltered () {
      let searchValue = this.$store.state.global.sidebarSearch

      let searchResults = null
      if (searchValue === '') {
        searchResults = this.menuLinksFormattedFlat
      }
      else {
        searchResults = new Fuse(this.menuLinksFormattedFlat, {
          shouldSort: false,
          threshold: 0.3,
          location: 0,
          distance: 100,
          minMatchCharLength: 1,
          keys: [
            "title",
            "description",
            "group",
            "module",
          ]
        }).search(searchValue)
      }

      if (!this.isCurrentWorldOwner) {
        searchResults = _.filter(searchResults, result => result.active === true)
      }

      return _.groupBy(searchResults, 'group')

    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
  }
}
</script>

<style lang="scss" scoped>
.attraction-item-wrapper:empty {
  display: none;
}
</style>
