<template>
  <auth-content>
    <el-header slot="header"></el-header>
    <form @submit.prevent="editProfile" v-show="user && global">
      <div class="card card-content">
        <div class="card-header">
          <el-card-title :title="$t('form.user.title.global')"/>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">

              <div class="file-upload-form">
                <label class="mt-2 mb-1 font-weight-bold">
                  {{ $t('form.user.picture') }}
                </label>
                <input ref="pictureInput" class="d-none" type="file" @change="previewPicture" accept="image/gif, image/jpeg, image/png">
                <div v-if="user.avatar">
                  <el-avatar v-if="user.avatar.base64" :src="user.avatar.base64"></el-avatar>
                  <el-avatar v-if="user.avatar.thumb" :src="user.avatar.thumb"></el-avatar>
                  <div @click="changePicture()" class="ml-2 btn btn-sm btn-light">{{ $t('form.user.picture_change') }}</div>
                </div>
                <div>
                  <div @click="changePicture()" class="btn btn-sm btn-light" v-if="!user.avatar">{{ $t('form.user.picture_add') }}</div>
                </div>
              </div>

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t('form.user.first_name') }}</label>
              <input required v-model="user.first_name" class="form-control" type="text" :placeholder="$t('form.user.first_name')"/>

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t('form.user.last_name') }}</label>
              <input required v-model="user.last_name" class="form-control" type="text" :placeholder="$t('form.user.last_name')"/>

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t('form.user.email') }}</label>
              <input required v-model="user.email" class="form-control" type="email" :placeholder="$t('form.user.email')"/>

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t('form.user.phone_number') }}</label>
              <VuePhoneNumberInput
                v-if="user.phone_number != null"
                ref="userPhoneNumberField"
                v-model="user.phone_number"
                :default-country-code="currentLocale"
                :preferred-countries="[currentLocale]"
                :placeholder="$t('form.user.phone_number')"
                :translations="$t('common.phone')"
                @update="phoneFieldChanged"
              />
              <label class="mt-2 mb-1 font-weight-bold">{{ $t('form.user.position') }}</label>
              <input v-model="user.position" class="form-control" type="text" :placeholder="$t('form.user.position')"/>

              <label class="mt-2 mb-1 font-weight-bold">{{ $t('form.user.birthday') }}</label>
              <input v-model="user.birthday" class="form-control" type="date" :placeholder="$t('form.user.birthday')"/>

              <div v-if="user.metadata">
                <label class="mt-2 mb-1 font-weight-bold">{{ $t('form.user.bio') }}</label>
                <textarea v-model="user.metadata.bio" class="form-control" :placeholder="$t('form.user.bio')"/>

                <label class="mt-2 mb-1 font-weight-bold">{{ $t('form.user.quote') }}</label>
                <input v-model="user.metadata.quote" class="form-control" type="text" :placeholder="$t('form.user.quote')"/>

                <label class="mt-2 mb-1 font-weight-bold">{{ $t('form.user.carreer') }}</label>
                <textarea v-model="user.metadata.carreer" class="form-control" :placeholder="$t('form.user.carreer')"/>

                <label class="mt-2 mb-1 font-weight-bold">{{ $t('form.user.expertise') }}</label>
                <input-tag v-model="user.metadata.expertise" class="form-control" :placeholder="$t('form.user.expertise')" />

                <label class="mt-2 mb-1 font-weight-bold">{{ $t('form.user.social') }}</label>

                <div v-for="linkKey in user.metadata.social.length + 1" :key="linkKey-1" class="mb-1 form-group form-group-feedback form-group-feedback-left">
                  <input v-model="user.metadata.social[linkKey-1]" type="url" class="form-control" :placeholder="$t('common.link')">
                  <div class="form-control-feedback">
                    <a v-if="user.metadata.social[linkKey-1] == user.metadata.social[linkKey-1]" class="text-dark" :href="user.metadata.social[linkKey-1]" target="_new"><i :class="socialIcon(user.metadata.social[linkKey-1])"></i></a>
                  </div>
                </div>

              </div>

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t('form.user.language') }}</label>
              <select
                :placeholder="$t('form.user.language')"
                v-model="user.language_id"
                class="form-control"
                @change="changeLanguage"
              >
                <!-- <option selected value="2">🇫🇷 Français</option> -->
                <option v-for="(item, key) in global.languages" :key="item.id" :value="item.id" :selected="item.id == user.language_id">{{ item.label }}</option>
              </select>

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t('form.user.timezone') }}</label>
              <select
                :placeholder="$t('form.user.timezone')"
                v-model="user.timezone_id"
                class="form-control"
              >
                <option v-for="(item, key) in global.timezones" :key="key" :value="key" :selected="key == user.timezone_id">{{ item }}</option>
              </select>

            </div>
          </div>

        </div>

        <div class="card-footer">
          <button v-if="!loadingProfile" class="btn btn-lg btn-success text-white">{{ $t('common.save') }}</button>
          <div v-if="loadingProfile" class="bg-white p-2">
            <span class="text-primary"><loader></loader></span>
          </div>
        </div>

      </div>
    </form>

    <form @submit.prevent="editWorldUserInfo" v-if="user && hasWorld && world_users">
      <div class="card card-content">
        <div class="card-header">
          <el-card-title :title="$t('form.user.title.world')"/>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">

              <div v-if="world_users">
                <label class="mt-2 mb-1 font-weight-bold">{{ $t('form.user.manager') }}</label>
                <el-select
                  :options="world_users"
                  :keys="{
                    value: 'id',
                    title: 'full_name',
                    avatar: 'avatar.thumb',
                    detail: 'email'
                  }"
                  v-model="user_world_info.manager_id"
                  :placeholder="$t('form.user.manager')"
                ></el-select>
              </div>

            </div>
          </div>
        </div>

        <div class="card-footer">
          <button v-if="!loadingWorldInfos" class="btn btn-lg btn-success text-white">{{ $t('common.save') }}</button>
          <div v-if="loadingWorldInfos" class="bg-white p-2">
            <span class="text-primary"><loader /></span>
          </div>
        </div>

      </div>
    </form>

    <div class="mt-3 text-center" v-if="mailDpo && mailDpo != ''">
      <span class="small text-grey cursor-pointer" @click="openModal('modalDeleteAccount')">{{ $t('form.user.dpo_delete_account') }}</span>
    </div>
    <el-modal id="modalDeleteAccount" :title="$t('form.user.dpo_delete_account')" v-if="mailDpo && mailDpo != ''">
      {{ $t('form.user.dpo_delete_account_modal_text', {mail: mailDpo}) }}
    </el-modal>

  </auth-content>
</template>

<script>

import _ from 'lodash'
import store from '../../store'

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import {
  USER_UPDATE,
  GLOBAL_DATA,
  WORLD_USERS_REQUEST,
  WORLD_USER_INFOS_CHANGE,
} from '../../store/mutations-types'

export default {
  name: 'UserProfileForm',
  components: {
    VuePhoneNumberInput,
  },
  data () {
    return {
      mailDpo: process.env.VUE_APP_MAIL_DATA_DELETE,
      currentLanguage: null,
      loadingProfile: false,
      loadingWorldInfos: false,
      user: false,
      global: false,
      world_users: false,
      phoneFormatted: null,
      phoneCountryCode: null,
      user_world_info: {
        manager_id: null,
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('global/' + GLOBAL_DATA).then(data => {
      next(vm => {
        let userData = {...vm.$store.getters['auth/getProfile']}

        delete userData.phone_verified_at
        delete userData.phone_verification_code

        if (!userData.metadata['bio']) {
          userData.metadata = Object.assign({}, userData.metadata, { bio: '' })
        }
        if (!userData.metadata['quote']) {
          userData.metadata = Object.assign({}, userData.metadata, { quote: '' })
        }
        if (!userData.metadata['carreer']) {
          userData.metadata = Object.assign({}, userData.metadata, { carreer: '' })
        }
        if (!userData.metadata['expertise']) {
          userData.metadata = Object.assign({}, userData.metadata, { expertise: [] })
        } else {
          userData.metadata.expertise = userData.metadata.expertise.split(',')
        }
        if (!userData.metadata['social']) {
          userData.metadata = Object.assign({}, userData.metadata, { social: [] })
        } else {
          userData.metadata.social = userData.metadata.social.split(/\n/)
        }
        if (userData.phone_number == null) {
          userData.phone_number = ''
        }
        vm.currentLanguage = userData.language_code

        vm.user = userData
        vm.global = {...data}
      })
    })
  },
  methods: {
    phoneFieldChanged (e) {
      this.phoneFormatted = e.formattedNumber
      this.phoneCountryCode = e.countryCode
      if (this.user && e.formatNational) {
        this.user.phone_number = e.formatNational
      }
    },
    socialIcon (link) {
      if (!link) {
        return 'icon-link'
      }
      else if (link.indexOf('facebook') !== -1) { return 'icon-facebook' }
      else if (link.indexOf('twitter') !== -1) { return 'icon-twitter' }
      else if (link.indexOf('instagram') !== -1) { return 'icon-instagram' }
      else if (link.indexOf('youtube') !== -1) { return 'icon-youtube' }
      else if (link.indexOf('vimeo') !== -1) { return 'icon-vimeo' }
      else if (link.indexOf('flickr') !== -1) { return 'icon-flickr' }
      else if (link.indexOf('dribbble') !== -1) { return 'icon-dribbble' }
      else if (link.indexOf('wordpress') !== -1) { return 'icon-wordpress' }
      else if (link.indexOf('dropbox') !== -1) { return 'icon-dropbox' }
      else if (link.indexOf('github') !== -1) { return 'icon-github' }
      else if (link.indexOf('google') !== -1) { return 'icon-google' }
      else if (link.indexOf('onedrive') !== -1) { return 'icon-onedrive' }
      else if (link.indexOf('blogger') !== -1) { return 'icon-blogger' }
      else if (link.indexOf('tumblr') !== -1) { return 'icon-tumblr' }
      else if (link.indexOf('soundcloud') !== -1) { return 'icon-soundcloud' }
      else if (link.indexOf('skype') !== -1) { return 'icon-skype' }
      else if (link.indexOf('reddit') !== -1) { return 'icon-reddit' }
      else if (link.indexOf('linkedin') !== -1) { return 'icon-linkedin2' }
      else if (link.indexOf('foursquare') !== -1) { return 'icon-foursquare' }
      else if (link.indexOf('paypal') !== -1) { return 'icon-paypal' }
      else if (link.indexOf('yelp') !== -1) { return 'icon-yelp' }
      else if (link.indexOf('bitbucket') !== -1) { return 'icon-git' }
      return 'icon-link'
    },
    previewPicture (event) {
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.user.avatar = {}
          this.user.avatar.base64 = e.target.result
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },
    changeLanguage (e) {
      this.$i18n.locale = this.global.languages[e.target.value].language
    },
    changePicture () {
      this.user.avatar = null
      this.$refs.pictureInput.click()
    },
    editProfile () {
      this.loadingProfile = true
      this.user.user_id = this.user.id

      let userData = _.cloneDeep(this.user)
      if (this.phoneFormatted && this.phoneFormatted != null) {
        userData.phone_number = this.phoneFormatted
      }

      userData.metadata.expertise = userData.metadata.expertise.join(',')
      userData.metadata.social = userData.metadata.social.join('\n')

      this.$store.dispatch('auth/' + USER_UPDATE, userData).then((user) => {

        // check if phone need validation
        if (user.phone_verified_at == null && this.isWorldOwner) {
          this.$router.push({name: 'Phone Verification'})
        }

        this.loadingProfile = false
        this.notifSuccess(this.$t('form.user.profile_edit_success'))

        let userData = _.cloneDeep(user)
        if (!userData.metadata['bio']) {
          userData.metadata = Object.assign({}, userData.metadata, { bio: '' })
        }
        if (!userData.metadata['quote']) {
          userData.metadata = Object.assign({}, userData.metadata, { quote: '' })
        }
        if (!userData.metadata['carreer']) {
          userData.metadata = Object.assign({}, userData.metadata, { carreer: '' })
        }
        if (!userData.metadata['expertise']) {
          userData.metadata = Object.assign({}, userData.metadata, { expertise: [] })
        } else {
          userData.metadata.expertise = userData.metadata.expertise.split(',')
        }
        if (!userData.metadata['social']) {
          userData.metadata = Object.assign({}, userData.metadata, { social: [] })
        } else {
          userData.metadata.social = userData.metadata.social.split(/\n/)
        }

        if (userData.phone_number && userData.phone_number != '' && this.phoneCountryCode != null) {
          userData.phone_number = this.$refs.userPhoneNumberField.getParsePhoneNumberFromString({phoneNumber: userData.phone_number, countryCode: this.phoneCountryCode}).formatNational
        }

        delete userData.phone_verified_at
        delete userData.phone_verification_code

        this.user = userData

        // Reload page if language changed
        if (this.user.language_code != this.currentLanguage) {
          window.location.reload()
        }

      })
      .catch(error => {
        this.loadingProfile = false
        this.notifError(error)
      })
    },
    editWorldUserInfo: function () {
      this.loadingWorldInfos = true

      this.$store.dispatch('world/' + WORLD_USER_INFOS_CHANGE, {
        worldId: this.userWorld.id,
        managerId: this.user_world_info.manager_id,
      }).then((user) => {
        this.loadingWorldInfos = false
        this.notifSuccess(this.$t('form.user.profile_edit_success'))
      })
      .catch(error => {
        this.loadingWorldInfos = false
        this.notifError(error)
      })
    },
    loadWorldUsers () {
      this.$store.dispatch('world/' + WORLD_USERS_REQUEST, this.userWorld.id).then(world_users => {
        this.world_users = world_users.filter(item => {
          return item.id != this.userId;
        })
      })
    },
  },
  created () {
    if (this.hasWorld) {
      this.loadWorldUsers()

      this.user_world_info = {
        manager_id: this.userWorld.pivot.manager_id,
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale.split('-')[0].toUpperCase()
    },
    hasWorld () {
      return this.$store.getters['auth/hasWorld']
    },
    isWorldOwner () {
      return this.$store.getters['auth/isWorldOwner']
    },
    userWorld () {
      return this.$store.getters['auth/getWorld']
    },
    userId () {
      return this.$store.getters['auth/getId']
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
